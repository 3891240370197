/* eslint-disable no-shadow */
import api from './api';

const profileApi = (api) => {
  const endpoint = '/shipowner';
  const fetchProfile = ({ headers }) => {
    const url = `${endpoint}/subProfile/me/`;
    const config = {
      headers
    };

    return Promise.all([api.get(url, config), fetchProfileTemplate({ headers })]);
  };

  const fetchHeaderData = ({ headers }) => {
    const url = `${endpoint}/subProfile/me/header/`;
    const config = {
      headers
    };
    return api.get(url, config);
  };

  const fetchPublicProfile = ({ headers }) => {
    const url = `${endpoint}/subProfile/me/public`;
    const config = {
      headers
    };

    return Promise.all([api.get(url, config), fetchProfileTemplate({ headers })]);
  };

  const fetchProfileTemplate = ({ headers }) => {
    const url = `${endpoint}/subProfile/me/edit`;
    const config = {
      headers
    };
    return api.get(url, config);
  };

  const updateProfile = ({ headers, profileBody }) => {
    const url = `${endpoint}/subProfile/me/edit`;
    const data = profileBody;
    return api.put({
      url,
      headers,
      data
    });
  };

  const getSeafarerById = ({ headers, seafarerId, params }) => {
    const url = `${endpoint}/subProfile/seafarer/${seafarerId}`;
    const config = {
      headers,
      params
    };
    return Promise.all([
      api.get(url, config),
      fetchSeafarerProfileTemplateById({ headers, subProfileId: seafarerId })
    ]);
  };

  const fetchSeafarerProfileTemplateById = ({ headers, subProfileId }) => {
    const url = `${endpoint}/subProfile/seafarer/${subProfileId}/edit`;
    const config = {
      headers
    };
    return api.get(url, config);
  };

  const addCommentToSeafarer = ({ headers, seafarerProfileId, commentBody }) => {
    const url = `${endpoint}/profile/${seafarerProfileId}/comment`;
    const data = commentBody;
    return api.put({ url, headers, data });
  };

  const addVerificationRequest = ({ headers, seafarerId }) => {
    const url = `${endpoint}/subProfile/seafarer/${seafarerId}/verificationRequest`;
    return api.post({ url, headers });
  };

  const updateViewedProfileStatus = ({ headers, seafarerId }) => {
    const url = `${endpoint}/status/view/${seafarerId}`;
    const config = {
      headers
    };
    return api.get(url, config);
  };

  return {
    fetchProfile,
    fetchPublicProfile,
    fetchHeaderData,
    fetchProfileTemplate,
    updateProfile,
    getSeafarerById,
    addCommentToSeafarer,
    addVerificationRequest,
    updateViewedProfileStatus
  };
};

export default profileApi(api);
